<template>
  <div>
    <MevcutFetchError
      v-if="ads.error"
      :message="t('fetch_failed', { data: t('announcements') })"
    />
    <HomeAdMainMobileSwiper
      v-if="isMobileOrTablet&&ads.items?.length"
      :ads="ads.items"
    />
    <HomeAdMainDesktop
      v-else-if="adsTypes.length"
      :ads-types="adsTypes"
      :active-key-type="activeKeyType"
      :active-ads-group-by-type="activeAdsGroupByType"
      :active-background="activeBackground"
      @select-type="selectType($event)"
      @select-background="selectBackground"
    />
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import type { FetchError } from 'ofetch'
import type { Ad } from '~~/composables/useMenuModel'
const { isMobileOrTablet } = useDevice()

const props = defineProps<{
  ads: { error?: FetchError<any> | null; items: Ad[] | null }
}>()

const { t } = useI18n()

const adsMappedByType = props.ads.items?.reduce((r, a) => {
  r[a.type] = [...(r[a.type] || []), a]
  return r
}, {} as Record<string, Ad[]>)

const adsTypes = Object.keys(adsMappedByType || {}) || []

const activeKeyType = ref(adsTypes[0])

const activeAdsGroupByType = ref(
  adsMappedByType ? adsMappedByType[activeKeyType.value] : []
)

const activeBackground = ref(
  useHttpUrl(activeAdsGroupByType.value[0]?.background)
)

function selectType(value: string) {
  activeKeyType.value = value

  activeAdsGroupByType.value = adsMappedByType
    ? adsMappedByType[activeKeyType.value]
    : []

  activeBackground.value = useHttpUrl(activeAdsGroupByType.value[0]?.background)
}

function selectBackground(index: number) {
  const background = activeAdsGroupByType.value[index]?.background

  if (background) {
    activeBackground.value = useHttpUrl(background)
  }
}
</script>
