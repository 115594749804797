<template>
  <div class="relative text-text_color">
    <div v-if="activeBackground" class="absolute inset-0 z-0">
      <NuxtImg
        provider="cacheIpx"
        preset="modified"
        loading="lazy"
        :src="activeBackground"
        :alt="activeBackground"
        width="150"
        height="86"
        sizes="sm:225px md:300px lg:450px"
        class="blur-sm w-full h-full object-cover"
      />
    </div>

    <div class="relative mevcut-container pt-4 pb-2">
      <div class="w-full lg:w-11/12 mx-auto flex items-center justify-end">
        <RikazHorizontalDrag class="h-10">
          <div class="flex">
            <button
              v-for="key in adsTypes"
              :key="`ad-hooper-slider-${key}`"
              class="relative px-3 py-1 mx-1 text-text_color bg-bg_color rounded-full focus:outline-none"
              :class="{ tail: activeKeyType == key }"
              @click="emit('select-type', key)"
            >
              {{ key }}
            </button>
          </div>
        </RikazHorizontalDrag>
      </div>

      <MevcutSwiper
        :key="`swiper-${activeKeyType}`"
        thumb
        thumb-slide-class="ads-slide"
        :items="activeAdsGroupByType"
        @slide-change="emit('select-background', $event)"
      >
        <template #slide="{ item }">
          <HomeAdSlide :ad="item" :width="250" :height="100" />
        </template>

        <template #thumb="{ item }">
          <NuxtImg
            provider="cacheIpx"
            preset="modified"
            loading="lazy"
            :src="item.image"
            width="64"
            height="40"
            class="w-16 h-10 rounded-lg object-cover"
            :alt="`thumbnail-${item.id}`"
          />
        </template>
      </MevcutSwiper>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Ad } from '~~/composables/useMenuModel'

defineProps<{
  adsTypes: string[]
  activeBackground: string
  activeKeyType: string
  activeAdsGroupByType: Ad[]
}>()
const emit = defineEmits(['select-type', 'select-background'])
</script>

<style>
.ads-slide {
  @apply w-max bg-bg_color rounded-lg shadow cursor-pointer mx-1 border-2 border-bg_color;
}
#secondary-swiper > .swiper > .swiper-pagination.main-pagination {
  @apply top-auto bottom-4;
}
.swiper-pagination.main-pagination > .swiper-pagination-bullet-active {
  @apply w-3 bg-nav_color rounded-full !important;
}
</style>
